<template>
  <v-form v-model="validateUser">
    <v-container
      style="background-color:#fafafa; max-width:650px;"
      class="pa-9"
      rounded
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>
            Experiencia de Usuario
          </h1>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Comercial"
            v-model="experience.distribuidor"
            prepend-icon="mdi-domain"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="experience.date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="experience.date"
                label="Fecha del servicio"
                prepend-icon="mdi-calendar"
                readonly
                :rules="requiredRules"
                required
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="experience.date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(experience.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Nombre"
            v-model="experience.nombre"
            prepend-icon="mdi-account"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="experience.apellido"
            label="Apellido"
            :rules="requiredRules"
            required
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="experience.telefono"
            label="Teléfono"
            type="number"
            :rules="requiredRules"
            required
            prepend-icon="mdi-cellphone"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Email"
            v-model="experience.email"
            :rules="emailRules"
            required
            prepend-icon="mdi-email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Número de serie"
            :rules="requiredRules"
            required
            v-model="experience.numeroSerie"
            prepend-icon="mdi-motorbike"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="modelos"
            v-model="experience.modelo"
            prepend-icon="mdi-ticket-percent"
            label="Modelo"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            ¿Fueron resueltos todos los problemas de servicio en su visita?
          </h2>
          <v-radio-group
            v-model="experience.resueltos"
            :rules="requiredRules"
            required
            row
          >
            <v-radio label="Sí" value="si"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            ¿Su motocicleta fue entregada limpia y en el tiempo prometido?
          </h2>
          <v-radio-group
            v-model="experience.entregada"
            :rules="requiredRules"
            required
            row
          >
            <v-radio label="Sí" value="si"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            ¿Cómo califica el precio del servicio efectuado a su moto?
          </h2>
          <v-radio-group
            v-model="experience.calificacionPrecio"
            row
            :rules="requiredRules"
            required
          >
            <v-radio label="Barato" value="barato"></v-radio>
            <v-radio label="Razonable" value="razonable"></v-radio>
            <v-radio label="Caro" value="caro"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            ¿Cómo fue la actitud del personal de servicio?
          </h2>
          <v-radio-group
            v-model="experience.actitud"
            row
            :rules="requiredRules"
            required
          >
            <v-radio label="Amable" value="amable"></v-radio>
            <v-radio label="Grosera" value="grosera"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            ¿Cómo califica la limpieza del taller de servicio?
          </h2>
          <v-radio-group
            v-model="experience.limpieza"
            row
            :rules="requiredRules"
            required
          >
            <v-radio label="Excelente" value="excelente"></v-radio>
            <v-radio label="Buena" value="buena"></v-radio>
            <v-radio label="Mala" value="mala"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            Disponibilidad de refacciones genuinas
          </h2>
          <v-radio-group
            v-model="experience.refacciones"
            row
            :rules="requiredRules"
            required
          >
            <v-radio label="Sí" value="si"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            ¿Cómo califica nuestro taller de manera general?
          </h2>
          <v-radio-group
            v-model="experience.calificacionGeneral"
            row
            :rules="requiredRules"
            required
          >
            <v-radio label="Excelente" value="excelente"></v-radio>
            <v-radio label="Bueno" value="bueno"></v-radio>
            <v-radio label="Pobre" value="pobre"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <h2 class="text-left">
            Basado en su experiencia de servicio, ¿Nos recomendaría con amigos
            y/o familiares?
          </h2>
          <v-radio-group
            v-model="experience.recomendacion"
            row
            :rules="requiredRules"
            required
          >
            <v-radio label="Sí" value="si"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            large
            :loading="cargando"
            block
            rounded
            :disabled="disable"
            class="white--text"
            @click="enviarExperience"
            >Enviar</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import PostService from "../PostService";
import rules from "../../mixins/rules";
export default {
  name: "userExperience",
  mixins: [rules],
  data: () => ({
    menu: false,
    experience: {
      distribuidor: "",
      date: null,
      nombre: "",
      apellido: "",
      telefono: "",
      email: "",
      numeroSerie: "",
      modelo: "",
      resueltos: null,
      entregada: null,
      calificacionPrecio: null,
      actitud: null,
      limpieza: null,
      refacciones: null,
      calificacionGeneral: null,
      recomendacion: null
    },
    modelos: [
      "DOMINAR D 400 ABS-EXP",
      "DOMINAR 400 UG",
      "BOXER 150 BM",
      "PLATINA 125",
      "PLATINA 100",
      "PULSAR 125 NS",
      "PULSAR 150 Neon",
      "PULSAR 220 Neon",
      "PULSAR NS 200",
      "PULSAR 200 FI ABS",
      "PULSAR 200 RS",
      "PULSAR NS 160 TD",
      "AVENGER 220 CRUISE",
      "DOMINAR 250 UG",
      "PULSAR N250",
    ],
    validateUser: false,
    cargando: false,
    disable: false
  }),
  methods: {
    async enviarExperience() {
      this.cargando = true;
      this.disable = true;
      if (!this.validateUser) {
        this.cargando = false;
        this.disable = false;
        return this.$store.dispatch("setSnackBarProps", {
          color: "error",
          mensaje: "Algún campo falta. Por favor intenta de nuevo.",
          mostrar: true
        });
      }
      try {
        const respuesta = await PostService.postExperience(this.experience);
        this.$store.dispatch("setSnackBarProps", {
          mensaje: respuesta.data,
          mostrar: true,
          color: "success"
        });
        this.$router.push("/exito");
      } catch (error) {
        this.$store.dispatch("setSnackBarProps", {
          mostrar: true,
          mensaje: "Ha ocurrido un error. Intente de nuevo más tarde",
          color: "error"
        });
      }
      //this.limpiarForm();
      this.cargando = false;
    },
    limpiarForm() {
      this.experience = {
        distribuidor: "",
        date: null,
        nombre: "",
        apellido: "",
        telefono: "",
        email: "",
        numeroSerie: "",
        modelo: "",
        resueltos: null,
        entregada: null,
        calificacionPrecio: null,
        actitud: null,
        limpieza: null,
        refacciones: null,
        calificacionGeneral: null,
        recomendacion: null
      };
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
h1 {
  font-family: "Open Sans", sans-serif;
  color: rgb(59, 59, 59);
  font-size: 38px;
}
h2 {
  font-family: "Open Sans", sans-serif;
  color: rgb(92, 92, 92);
  font-size: 17px;
}
</style>
